<template> 

  <div>

    <Loader text="Ładowanie aplikacji" v-if="!user.id" />

    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">{{ app.title }}</h2>

      <AppReelsKeywordsGenerator v-if="app.slug == 'reels-keywords-generator'"/>
      <AppInfluencerGenerator v-if="app.slug == 'influencer-generator'"/>
      <App1MilionCoaching v-if="app.slug == '1milion-coaching'"/>
      <AppAiPostCreator v-if="app.slug == 'ai-post-creator'"/>
      <AppSoMeAiWriter v-if="app.slug == 'someai-writer'"/>
      <AppAiSellingMachine v-if="app.slug == 'ai-selling-machine'"/>
      <AppAiVirtualAssistanceAi v-if="app.slug == 'virtual-assistance-ai'"/>
      <AppCelebrityPostWriterAi v-if="app.slug == 'celebrity-post-writer-ai'"/>
      <AppTranslatorAi v-if="app.slug == 'translator-ai'"/>
      <AppGraphicAi v-if="app.slug == 'graphic-ai'"/>
      <AppGraphicNAi v-if="app.slug == 'graphic-n-ai'"/>
      <AppGraphicNNAi v-if="app.slug == 'graphic-nn-ai'"/>
      <AppHeadlineGeneratorAi v-if="app.slug == 'headline-generator-ai'"/>
      <AppOfferGeneratorAi v-if="app.slug == 'offer-generator-ai'"/>
      <AppPromptGenerator v-if="app.slug == 'prompt-generator'"/>
      <AppCopyPuzzleAi v-if="app.slug == 'copy-puzzle-ai'"/>
      <AppCopyLegendAi v-if="app.slug == 'copy-legend-ai'"/>
      <AppCopyBoosterAi v-if="app.slug == 'copy-booster-ai'"/>
      <AppMemeGeneratorAi v-if="app.slug == 'meme-generator-ai'"/>
      <AppCopyAssistantAi v-if="app.slug == 'copy-assistant-ai'"/>
      <AppBizBrainAi v-if="app.slug == 'biz-brain-ai'"/>
      <AppArticleGeniusAi v-if="app.slug == 'article-genius-ai'"/>
      <AppAskAi v-if="app.slug == 'ask-ai'"/>
      <AppVirtualoAi v-if="app.slug == 'virtualo-ai'"/>
      <AppArticleHeadlineAi v-if="app.slug == 'article-headline-ai'"/>
      <AppKorektorAi v-if="app.slug == 'korektor-ai'"/>
      <AppBussinesMentorAi v-if="app.slug == 'bussines-mentor-ai'"/>
      <AppMotivationalPostAi v-if="app.slug == 'motivational-post-ai'"/>
      <AppHeadlineBooster v-if="app.slug == 'headline-booster'"/>
      <AppBlogPostAi v-if="app.slug == 'blog-post-ai'"/>
      <AppSeoWriterAi v-if="app.slug == 'seo-writer-ai'"/>
      <AppEcomCopywriterAI v-if="app.slug == 'ecom-copywriter-ai'"/>
      <AppCopyMasterAI v-if="app.slug == 'copy-master-ai'"/>
      <AppEcomArticleSEOAI v-if="app.slug == 'ecom-article-seo-ai'"/>
      <App12XSellingMachineAI v-if="app.slug == '12x-selling-machine-ai'"/>
      <AppEcomAudytorAI v-if="app.slug == 'ecom-audytor-ai'"/>
      <AppKeywordsGeneratorAI v-if="app.slug == 'keywords-generator-ai'"/>
      <AppPerfectCopyAI v-if="app.slug == 'perfect-copy-ai'"/>
      <AppEcomFunnelGeneratorAI v-if="app.slug == 'ecom-funnel-generator-ai'"/>
      <AppArticleSEOIdeas v-if="app.slug == 'article-seo-ideas'"/>
      <AppGraphicAltAI v-if="app.slug == 'graphic-alt-ai'"/>
      <AppRealEstateAI v-if="app.slug == 'real-estate-ai'"/>
      <AppEcomGeneratorAI v-if="app.slug == 'ecom-generator-ai'"/>
      <AppSocialSellingMachineAI v-if="app.slug == 'social-selling-machine-ai'"/>
      <AppTargetDetectiveAI v-if="app.slug == 'target-detective-ai'"/>
      <AppTOPBrandCopyAI v-if="app.slug == 'top-brand-copy-ai'"/>
      <AppViralOpenerAI v-if="app.slug == 'viral-opener-ai'"/>
      <AppTikTokViralGeneratorAI v-if="app.slug == 'tiktok-viral-generator-ai'"/>
      <AppEduReelsGeneratorAI v-if="app.slug == 'edu-reels-generator-ai'"/>
      <AppViralHookGeneratorAI v-if="app.slug == 'viral-hook-generator-ai'"/>
      <AppSlideGenerator v-if="app.slug == 'slide-generator'"/>
      <AppQuizzGeneratorAI v-if="app.slug == 'quizz-generator-ai'"/>
      <AppModuleTitleGenerator  v-if="app.slug == 'module-title-generator-ai'"/>
      <AppMindMapGeneratorAI  v-if="app.slug == 'mind-map-generator-ai'"/>
      <AppCourseTitleGeneratorAI  v-if="app.slug == 'course-title-generator-ai'"/>
      <AppCoursePlannerAI  v-if="app.slug == 'course-planner-ai'"/>
      <AppCourseAssistantAI  v-if="app.slug == 'course-assistant-ai'"/>
      <AppCourseIdeaGeneratorAI  v-if="app.slug == 'course-idea-generator-ai'"/>
      <AppCourseValidatorAI  v-if="app.slug == 'course-validator-ai'"/>
      <AppCourseOfferGenerator  v-if="app.slug == 'course-offer-generator-ai'"/>
      <AppEmailSellingSequenceAI  v-if="app.slug == 'email-selling-sequence-ai'"/>
      <AppExpertGeniusAI  v-if="app.slug == 'expert-genius-ai'"/>
      <AppAICourseGenerator  v-if="app.slug == 'ai-course-generator'"/>
      <AppStyleClonerAI  v-if="app.slug == 'style-cloner-ai'"/>
      <AppStorytellerAI  v-if="app.slug == 'storyteller-ai'"/>
      <AppChapterTitleGeneratorAI  v-if="app.slug == 'chapter-title-generator-ai'"/>
      <AppEbookSellingCheckerAI  v-if="app.slug == 'ebook-selling-checker-ai'"/>
      <AppIlustratorAI  v-if="app.slug == 'ilustrator-ai'"/>
      <AppOutroEbookGeneratorAI  v-if="app.slug == 'outro-ebook-generator-ai'"/>
      <AppIntroEbookGeneratorAI  v-if="app.slug == 'intro-book-generator-ai'"/>
      <AppEbookArticleAI  v-if="app.slug == 'ebook-article-ai'"/>
      <AppEbookXXLAI  v-if="app.slug == 'ebook-xxl-ai'"/>
      <AppEbookAssistantAI  v-if="app.slug == 'ebook-assistant-ai'"/>
      <AppEbookPromoCalendar v-if="app.slug == 'ebook-promo-calendar'"/>
      <AppEbookOfferGeneratorAI  v-if="app.slug == 'ebook-offer-generator-ai'"/>
      <AppVideoToEbookAI  v-if="app.slug == 'video-to-ebook-ai'"/>
      <AppEbookPromotorAI  v-if="app.slug == 'ebook-promotor-ai'"/>
      <AppEbookSalesFunnelAI  v-if="app.slug == 'ebook-sales-funnel'"/>
      <AppContentsGeneratorAI  v-if="app.slug == 'contents-generator-ai'"/>
      <AppEbookIdeaGeneratorAI  v-if="app.slug == 'ebook-idea-generator-ai'"/>
      <AppEbookTitleGenerator  v-if="app.slug == 'ebook-title-generator-ai'"/>
      <AppAuthorAI  v-if="app.slug == 'author-ai'"/>
      <AppEbookEmailSequenceAI  v-if="app.slug == 'ebook-email-sequence-ai'"/>
      <AppAIEbookGenerator  v-if="app.slug == 'ai-ebook-generator'"/>


    </div>

  </div>
  
</template>

<script>
  
  import Loader from '../../elements/Loader';
  import AppReelsKeywordsGenerator from '../../elements/Apps/AppReelsKeywordsGenerator';
  import AppInfluencerGenerator from '../../elements/Apps/AppInfluencerGenerator';
  import App1MilionCoaching from '../../elements/Apps/App1MilionCoaching';
  import AppAiPostCreator from '../../elements/Apps/AppAiPostCreator';
  import AppSoMeAiWriter from '../../elements/Apps/AppSoMeAiWriter';
  import AppAiSellingMachine from '../../elements/Apps/AppAiSellingMachine';
  import AppAiVirtualAssistanceAi from '../../elements/Apps/AppAiVirtualAssistanceAi';
  import AppCelebrityPostWriterAi from '../../elements/Apps/AppCelebrityPostWriterAi';
  import AppTranslatorAi from '../../elements/Apps/AppTranslatorAi';
  import AppGraphicAi from '../../elements/Apps/AppGraphicAi';
  import AppGraphicNAi from '../../elements/Apps/AppGraphicNAi';
  import AppGraphicNNAi from '../../elements/Apps/AppGraphicNNAi';
  import AppHeadlineGeneratorAi from '../../elements/Apps/AppHeadlineGeneratorAi';
  import AppOfferGeneratorAi from '../../elements/Apps/AppOfferGeneratorAi';
  import AppPromptGenerator from '../../elements/Apps/AppPromptGenerator';
  import AppCopyPuzzleAi from '../../elements/Apps/AppCopyPuzzleAi';
  import AppCopyLegendAi from '../../elements/Apps/AppCopyLegendAi';
  import AppCopyBoosterAi from '../../elements/Apps/AppCopyBoosterAi';
  import AppMemeGeneratorAi from '../../elements/Apps/AppMemeGeneratorAi';
  import AppCopyAssistantAi from '../../elements/Apps/AppCopyAssistantAi';
  import AppBizBrainAi from '../../elements/Apps/AppBizBrainAi';
  import AppArticleGeniusAi from '../../elements/Apps/AppArticleGeniusAi';
  import AppAskAi from '../../elements/Apps/AppAskAi';
  import AppVirtualoAi from '../../elements/Apps/AppVirtualoAi';
  import AppArticleHeadlineAi from '../../elements/Apps/AppArticleHeadlineAi';
  import AppKorektorAi from '../../elements/Apps/AppKorektorAi';
  import AppBussinesMentorAi from '../../elements/Apps/AppBussinesMentorAi';
  import AppMotivationalPostAi from '../../elements/Apps/AppMotivationalPostAi';
  import AppHeadlineBooster from '../../elements/Apps/AppHeadlineBooster';
  import AppBlogPostAi from '../../elements/Apps/AppBlogPostAi';
  import AppSeoWriterAi from '../../elements/Apps/AppSeoWriterAi';
  import AppEcomCopywriterAI from '../../elements/Apps/AppEcomCopywriterAI';
  import AppCopyMasterAI from '../../elements/Apps/AppCopyMasterAI';
  import AppEcomArticleSEOAI from '../../elements/Apps/AppEcomArticleSEOAI';
  import App12XSellingMachineAI from '../../elements/Apps/App12XSellingMachineAI';
  import AppEcomAudytorAI from '../../elements/Apps/AppEcomAudytorAI';
  import AppKeywordsGeneratorAI from '../../elements/Apps/AppKeywordsGeneratorAI';
  import AppPerfectCopyAI from '../../elements/Apps/AppPerfectCopyAI';
  import AppEcomFunnelGeneratorAI from '../../elements/Apps/AppEcomFunnelGeneratorAI';
  import AppArticleSEOIdeas from '../../elements/Apps/AppArticleSEOIdeas';
  import AppGraphicAltAI from '../../elements/Apps/AppGraphicAltAI';
  import AppRealEstateAI from '../../elements/Apps/AppRealEstateAI';
  import AppEcomGeneratorAI from '../../elements/Apps/AppEcomGeneratorAI';
  import AppSocialSellingMachineAI from '../../elements/Apps/AppSocialSellingMachineAI';
  import AppTargetDetectiveAI from '../../elements/Apps/AppTargetDetectiveAI';
  import AppTOPBrandCopyAI from '../../elements/Apps/AppTOPBrandCopyAI';
  import AppViralOpenerAI from '../../elements/Apps/AppViralOpenerAI';
  import AppTikTokViralGeneratorAI from '../../elements/Apps/AppTikTokViralGeneratorAI';
  import AppEduReelsGeneratorAI from '../../elements/Apps/AppEduReelsGeneratorAI';
  import AppViralHookGeneratorAI from '../../elements/Apps/AppViralHookGeneratorAI';
  import AppSlideGenerator from '../../elements/Apps/AppSlideGenerator';
  import AppQuizzGeneratorAI from '../../elements/Apps/AppQuizzGeneratorAI';
  import AppModuleTitleGenerator from '../../elements/Apps/AppModuleTitleGenerator';
  import AppMindMapGeneratorAI from '../../elements/Apps/AppMindMapGeneratorAI';
  import AppCourseTitleGeneratorAI from '../../elements/Apps/AppCourseTitleGeneratorAI';
  import AppCoursePlannerAI from '../../elements/Apps/AppCoursePlannerAI';
  import AppCourseAssistantAI from '../../elements/Apps/AppCourseAssistantAI';
  import AppCourseIdeaGeneratorAI from '../../elements/Apps/AppCourseIdeaGeneratorAI';
  import AppCourseValidatorAI from '../../elements/Apps/AppCourseValidatorAI';
  import AppCourseOfferGenerator from '../../elements/Apps/AppCourseOfferGenerator';
  import AppEmailSellingSequenceAI from '../../elements/Apps/AppEmailSellingSequenceAI';
  import AppExpertGeniusAI from '../../elements/Apps/AppExpertGeniusAI';
  import AppAICourseGenerator from '../../elements/Apps/AppAICourseGenerator';
  import AppStyleClonerAI from '../../elements/Apps/AppStyleClonerAI';
  import AppStorytellerAI from '../../elements/Apps/AppStorytellerAI';
  import AppChapterTitleGeneratorAI from '../../elements/Apps/AppChapterTitleGeneratorAI';
  import AppEbookSellingCheckerAI from '../../elements/Apps/AppEbookSellingCheckerAI';
  import AppIlustratorAI from '../../elements/Apps/AppIlustratorAI';
  import AppOutroEbookGeneratorAI from '../../elements/Apps/AppOutroEbookGeneratorAI';
  import AppIntroEbookGeneratorAI from '../../elements/Apps/AppIntroEbookGeneratorAI';
  import AppEbookArticleAI from '../../elements/Apps/AppEbookArticleAI';
  import AppEbookXXLAI from '../../elements/Apps/AppEbookXXLAI';
  import AppEbookAssistantAI from '../../elements/Apps/AppEbookAssistantAI';
  import AppEbookPromoCalendar from '../../elements/Apps/AppEbookPromoCalendar';
  import AppEbookOfferGeneratorAI from '../../elements/Apps/AppEbookOfferGeneratorAI';
  import AppVideoToEbookAI from '../../elements/Apps/AppVideoToEbookAI';
  import AppEbookPromotorAI from '../../elements/Apps/AppEbookPromotorAI';
  import AppEbookSalesFunnelAI from '../../elements/Apps/AppEbookSalesFunnelAI';
  import AppContentsGeneratorAI from '../../elements/Apps/AppContentsGeneratorAI';
  import AppEbookIdeaGeneratorAI from '../../elements/Apps/AppEbookIdeaGeneratorAI';
  import AppEbookTitleGenerator from '../../elements/Apps/AppEbookTitleGenerator';
  import AppAuthorAI from '../../elements/Apps/AppAuthorAI';
  import AppEbookEmailSequenceAI from '../../elements/Apps/AppEbookEmailSequenceAI';
  import AppAIEbookGenerator from '../../elements/Apps/AppAIEbookGenerator';


  export default {
    name: 'SectionApp',
    components: {
      Loader,
      AppReelsKeywordsGenerator,
      AppInfluencerGenerator,
      App1MilionCoaching,
      AppAiPostCreator,
      AppSoMeAiWriter,
      AppAiSellingMachine,
      AppAiVirtualAssistanceAi,
      AppCelebrityPostWriterAi,
      AppTranslatorAi,
      AppGraphicAi,
      AppGraphicNAi,
      AppGraphicNNAi,
      AppHeadlineGeneratorAi,
      AppOfferGeneratorAi,
      AppPromptGenerator,
      AppCopyPuzzleAi,
      AppCopyLegendAi,
      AppCopyBoosterAi,
      AppMemeGeneratorAi,
      AppCopyAssistantAi,
      AppBizBrainAi,
      AppArticleGeniusAi,
      AppAskAi,
      AppVirtualoAi,
      AppArticleHeadlineAi,
      AppKorektorAi,
      AppBussinesMentorAi,
      AppMotivationalPostAi,
      AppHeadlineBooster,
      AppBlogPostAi,
      AppSeoWriterAi,
      AppEcomCopywriterAI,
      AppCopyMasterAI,
      AppEcomArticleSEOAI,
      App12XSellingMachineAI,
      AppEcomAudytorAI,
      AppKeywordsGeneratorAI,
      AppPerfectCopyAI,
      AppEcomFunnelGeneratorAI,
      AppArticleSEOIdeas,
      AppGraphicAltAI,
      AppRealEstateAI,
      AppEcomGeneratorAI,
      AppSocialSellingMachineAI,
      AppTargetDetectiveAI,
      AppTOPBrandCopyAI,
      AppViralOpenerAI,
      AppTikTokViralGeneratorAI,
      AppEduReelsGeneratorAI,
      AppViralHookGeneratorAI,
      AppSlideGenerator,
      AppQuizzGeneratorAI,
      AppModuleTitleGenerator,
      AppMindMapGeneratorAI,
      AppCourseTitleGeneratorAI,
      AppCoursePlannerAI,
      AppCourseAssistantAI,
      AppCourseIdeaGeneratorAI,
      AppCourseValidatorAI,
      AppCourseOfferGenerator,
      AppEmailSellingSequenceAI,
      AppExpertGeniusAI,
      AppAICourseGenerator,
      AppStyleClonerAI,
      AppStorytellerAI,
      AppChapterTitleGeneratorAI,
      AppEbookSellingCheckerAI,
      AppIlustratorAI,
      AppOutroEbookGeneratorAI,
      AppIntroEbookGeneratorAI,
      AppEbookArticleAI,
      AppEbookXXLAI,
      AppEbookAssistantAI,
      AppEbookPromoCalendar,
      AppEbookOfferGeneratorAI,
      AppVideoToEbookAI,
      AppEbookPromotorAI,
      AppEbookSalesFunnelAI,
      AppContentsGeneratorAI,
      AppEbookIdeaGeneratorAI,
      AppEbookTitleGenerator,
      AppAuthorAI,
      AppEbookEmailSequenceAI,
      AppAIEbookGenerator
    },
    data() {
      return {
        app: {},
        user: {
          id: null,
          permissions: []
        },
      }
    },
 
    created() {
        
      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data 

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data.userId) {

          this.user.id = data.userId
          this.user.permissions = data.permissions


          if(this.$route.params.slug) {

            if(this.$route.params.app) 
              this.load(this.$route.params.slug, this.$route.params.app)
            else
              this.load(this.$route.params.slug);

          }

        }

      });

      // Set breadcumbs
      this.$root.$emit('system.breadcumber.set', [{label: "Aplikacje", link: "/apps"}])

    },

    methods: {
        
      load: function(slug) {

        if(slug) {
 
          this.axios.get(window.API_URL + "apps/getApp/" + slug).then((response) => {
            
            let data = response.data 
         
            this.app = data
             
            // Checking permissions
            if(!this.user.permissions.includes(this.app.neededPermissions)) {
              
              this.$router.push('/access-denied')
              
            }
 
          }) 
 
          window.scrollTo(0, 0)

        }

      }

    },

    watch: {

      '$route.params.slug' (slug) {

        this.load(slug, this.app.id)

      },

    },

    mounted() {
     
    }

  }

</script>

<style scoped>

  .table-of-contents > div {
    font-size: 0.9rem;
  }

  .list-group-item {
    border: 0px;
  }

  .list-group-item > a {
    color: #000;
  }

  .list-group-item > a.active {
    color: #165eff;
    font-weight: 600;
  }

  .app-title {
    font-size: 1.3rem;
  }
 
  .list-group-item:hover {
    background-color: #f9f9f9;
  }

  .list-group-item.current {
    background-color: #f9f9f9;
  }

</style> 