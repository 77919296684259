<template>

  <div class="pt-2 pb-4">

    <div>

      <b-row align-v="center">

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <label>Dodaj zdjęcie produktu:</label>

              <b-input-group>

                <b-form-file size="lg" v-model="file" ref="file" accept="image/jpeg, image/png, image/gif" placeholder="Kliknij i dodaj zdjęcie" browse-text="Wybierz"></b-form-file>

              </b-input-group>

            </div>

          </div>

        </b-col>

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz parametry produktu i inne kluczowe informacje dla opisu (opcjonalnie):</label>

              <b-input-group>

                <b-form-input v-model="parametry" size="lg" ref="parametry" placeholder="np. kolor, wymiary"></b-form-input>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>

      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">

        </b-col>

        <b-col lg="6">

          <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
            Generuj opis
          </b-button>

        </b-col>

      </b-row>


      <div class="mt-2" >

        <b-progress v-if="progress" class="ml-2" :value="progress" variant="success" striped animated></b-progress>
        <div v-html="user_log" class="pl-2 mt-2"></div>

        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje opis <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany opis</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">

            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>

        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>


  </div>

</template>

<script>

export default {
  name: 'AppEcomGeneratorAI',
  components: {
  },
  data() {
    return {
      isFinish: false,
      result: "",
      file: null,
      task_id: null,
      user_log: "",
      progress: 0,
      content: "",
      parametry: "",
      uploadedFileUrl: null,
      selectedText: '',
      query: "",
      finishContent: '',
      selectedSchema: null
    }
  },
  methods: {

    cancel: function () {

      this.task_id = null

    },

    check: function (txt) {

      if(txt) {

        this.user_log += ".";

        this.content = txt

        this.cancel()

        this.user_log += "<br/>";

        this.user_log += "<span class='material-symbols-outlined align-middle' style='color:#3ad729;'>task_alt</span> Trwa generowanie opisów";

        this.progress = 75

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";

        query = "Napisz opis produktu do sklepu internetowego, który zwiększy sprzedaż tego produktu. Oto opis produktu, do którego masz napisać opis copywriterski: "+this.content+". Oto cechy produktu: "+this.parametry+". Pisz językiem prostym i przekonywującym. Napisz 5 korzyści z zakupu tego produktu. Na końcu opisu zastosuj wezwanie do akcji.";

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {

          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          this.user_log = "";
          this.progress = 0

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany opis"

        }).catch(() => {

          this.$refs.generatePost.disabled = false

        })

      }


    },


    generateFinish: function () {

      if (!this.file) return false;

      this.isFinish = false
      this.$refs.generatePost.disabled = true
      this.progress = 0
      this.user_log = ""

      const formData = new FormData();

      formData.append('file', this.$refs['file'].files[0]);

      const headers = {'Content-Type': 'multipart/form-data'};

      this.axios.post("https://soft.funnelstar.io/api/images/upload/", formData, {
        withCredentials: true,
        headers
      }).then((response) => {

        let data = response.data

        this.uploadedFileUrl = data.file

        if (this.uploadedFileUrl) {

          this.user_log += "<span class='material-symbols-outlined align-middle' style='color:#3ad729;'>task_alt</span> Załadowano zdjęcie<br/>";
          this.progress = 25

          this.axios.post(window.API_URL + "images/describe/", {'url': this.uploadedFileUrl}, {
            withCredentials: true,
            timeout: 3000000
          }).then((response) => {

            let data = response.data

            if (data) {

              this.user_log += "<span class='material-symbols-outlined align-middle' style='color:#3ad729;'>task_alt</span> Trwa opisywanie zdjęcia";
              this.progress = 50

              this.check(data)

            } else {

              this.user_log += "<span class='material-symbols-outlined align-middle' style='color:#d72929;'>error</span> Błąd opisywania zdjęcia";
              this.progress = 0

            }

          }).catch(function () {


          })

        } else {

          this.user_log += "<span class='material-symbols-outlined align-middle' style='color:#d72929;'>error</span> Błąd ładowania zdjęcia";
          this.progress = 0

        }

      }).catch(() => {

        this.$refs.generatePost.disabled = false

      });


    },

    copyResults: function () {

      this.$refs.finishContent.style.display = "block";

      this.$refs.finishContent.focus();

      document.execCommand('copy');

      this.$refs.finishContent.style.display = "none"

      window.scrollTo(0, 0)

      this.$bvToast.toast('Treść została skopiowana do schowka', {
        title: 'Skopiowano',
        autoHideDelay: 5000,
        variant: 'success',
        solid: true,
        toaster: 'b-toaster-top-center',
        noCloseButton: true
      })

    }

  },
  computed: {},
  created() {

    this.$watch('finishContent', (value) => {

      var lines = value.split(/\r|\r\n|\n/);

      this.finishLinesCount = lines.length;

    })

  }

}
</script>

<style>

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: 600 !important;
}

code {
  color: #4e82f7 !important;
}
</style>